import { WithT } from "i18next";
import React from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import { withTranslation } from "react-i18next";

type Props = {
  createClick?: () => void;
} & WithT<any>;

const ToolbarTemplate: React.FC<Props> = ({
  createClick,
  t
}) => (
    <div className="d-flex">
        <button
            className='btn btn-discreet-primary btn-lg btn-icon-start'
            onClick={() => createClick && createClick() }>
            <i className='icon'>add</i>
            {t('ttdMyCases.createBtn')}
        </button>
    </div>
  );

  export default withTranslation()(ToolbarTemplate);