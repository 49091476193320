import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { format, isToday } from 'date-fns';
import { fr } from "date-fns/locale";

const resources = {
  en: {
    translation: {
      common: {
        itemDate: "{{ date, itemDate }}"
      },
      ttdMyCases: {
        viewSelector: {
          noData: {
            title: 'No open cases',
            message: 'Create a new case to report an issue.'
          },
          technicalIssue: {
            title: 'Technical issue',
            message: 'A problem occurred while loading this content.'
          }
        },
        title: 'Requests',
        subtitle: '5 most recently updated cases',
        retryBtn: 'Retry',
        demoBtn: 'Display with demo data',
        seeAllBtn: 'See all',
        createBtn: 'Create case',
        filterBtn: 'Open only',
        statusText: "Status detail: {{status}}",
        statuses: {
          new: 'New',
          inProgress: 'In progress',
          resolved: 'Resolved',
        },
        demoBadge: 'Demo data',
        caseDetail: {
          titleBadge: 'New',
          subtitle: 'Last exchange',
          date: "{{ date, exchangeDate }}",
          callDetail: {
            title: 'Call details',
            type: 'Type',
            from: 'From',
            to: 'To',
            anwseredBy: 'Anwsered By',
            time: 'Time of call',
            formattedTime: "{{ date, callDate }}"
          }
        }
      }
    }
  },
  fr: {
    translation: {
      common: {
        itemDate: "{{ date, itemDate }}"
      },
      ttdMyCases: {
        viewSelector: {
          noData: {
            title: 'Pas de case ouvert',
            message: 'Créez un nouveau case pour signaler un problème.'
          },
          technicalIssue: {
            title: 'Problème technique',
            message: 'Un problème est survenu pendant le chargement de ce contenu.'
          },
        },
        title: 'Requêtes',
        subtitle: '5 cases les plus récents',
        retryBtn: 'Réessayer',
        demoBtn: 'Afficher en démo',
        seeAllBtn: 'Voir tout',
        createBtn: 'Créer case',
        filterBtn: 'Ouverts uniquement',
        statusText: "Détail statut: {{status}}",
        statuses: {
          new: 'Nouveau',
          inProgress: 'En cours',
          resolved: 'Résolu',
        },
        demoBadge: 'Données de demo',
        caseDetail: {
          titleBadge: 'Nouveau',
          subtitle: 'Premier échange',
          date: "{{ date, exchangeDate }}",
          callDetail: {
            title: "Détail de l'appel",
            type: 'Type',
            from: 'De',
            to: 'A',
            anwseredBy: 'Répondu par',
            time: "Heure de l'appel",
            formattedTime: "{{ date, callDate }}"
          }
        }
      }
    }
  },
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
      format: (value, fmt, lng) => {
        switch (fmt) {
          case 'itemDate':
            if (isToday(value)) {
              return lng === 'fr' 
                ? format(value, 'p', { locale: fr})
                : format(value, 'p');
            }
            return lng === 'fr'
              ? format(value, 'dd MMM yyyy', { locale: fr })
              : format(value, 'dd MMM yyyy')
          case 'exchangeDate':
            return lng === 'fr'
              ? `${format(value, 'd MMMM yyyy', { locale: fr })} à ${format(value, 'kk:mm')}`
              : `${format(value, 'd MMMM yyyy')} at ${format(value, 'kk:mm')}`;
          case 'callDate':
            return lng === 'fr'
              ? `${format(value, 'd MMMM yyyy', { locale: fr })}, ${format(value, 'kk:mm')}`
              : `${format(value, 'd MMMM yyyy')}, ${format(value, 'kk:mm')}`
          default:
            return value;
        }
      }
    },
  });

export default i18next;