import { subDays } from 'date-fns';

import { Case } from "../models/my-cases/do-search-case.model";

const newCaseDate = new Date();
const inProgessDate = subDays(new Date(), 1);
const resolvedDate  = subDays(new Date(), 2);

const mocks: Case[] = [
  {
    metaData: {
      shortStatus: "New",
      resolutionDateTime: "",
      caseLabel: "Opération importante à venir - Cash management FR",
      creationDateTime: newCaseDate.toISOString()
    },
    userMetaData: {
      alertCount: 1,
      lastSeenDateTime: newCaseDate.toISOString(),
      isCreator: true,
      isFavourite: false,
      lastAlertDateTime: newCaseDate.toISOString(),
      isContact: true,
      isShared: false,
      latestVisibleInteraction: {
        id: "MSG-254",
        type: "MESSAGE"
      }
    },
    id: "SR-770631",
    content: {
      issueType: "",
      activity: "Custody",
      subActivity: "SUBACTIVITY_5",
      customer: {
        displayName: "ALLIANZ GLOBAL INVESTORS GMBH",
        bdrLevel: "EC",
        bdrId: "19460"
      }
    },
  },
  {
    metaData: {
      shortStatus: "New",
      resolutionDateTime: "",
      caseLabel: "Opération importante à venir - Cash management FR",
      creationDateTime: newCaseDate.toISOString()
    },
    userMetaData: {
      alertCount: 1,
      lastSeenDateTime: newCaseDate.toISOString(),
      isCreator: true,
      isFavourite: false,
      lastAlertDateTime: newCaseDate.toISOString(),
      isContact: true,
      isShared: false,
      latestVisibleInteraction: {
        id: "MSG-255",
        type: "MESSAGE"
      }
    },
    id: "SR-770621",
    content: {
      issueType: "",
      activity: "Custody",
      subActivity: "SUBACTIVITY_1",
      customer: {
        displayName: "ALLIANZ GLOBAL INVESTORS GMBH",
        bdrLevel: "EC",
        bdrId: "19460"
      }
    }
  },
  {
    metaData: {
      shortStatus: "In progress",
      resolutionDateTime: "",
      caseLabel: "Opération importante à venir - Cash management FR",
      creationDateTime: inProgessDate.toISOString()
    },
    userMetaData: {
      alertCount: 0,
      lastSeenDateTime: inProgessDate.toISOString(),
      isCreator: true,
      isFavourite: false,
      lastAlertDateTime: inProgessDate.toISOString(),
      isContact: true,
      isShared: false,
      latestVisibleInteraction: {
        id: "MSG-256",
        type: "MESSAGE"
      }
    },
    id: "SR-768627",
    content: {
      issueType: "",
      activity: "Custody",
      subActivity: "SUBACTIVITY_2",
      customer: {
        displayName: "ALLIANZ GLOBAL INVESTORS GMBH",
        bdrLevel: "EC",
        bdrId: "19460"
      }
    }
  },
  {
    metaData: {
      shortStatus: "In progress",
      resolutionDateTime: "",
      caseLabel: "Opération bloquée - Cash management FR",
      creationDateTime: inProgessDate.toISOString()
    },
    userMetaData: {
      alertCount: 0,
      lastSeenDateTime: inProgessDate.toISOString(),
      isCreator: true,
      isFavourite: false,
      lastAlertDateTime: inProgessDate.toISOString(),
      isContact: true,
      isShared: false,
      latestVisibleInteraction: {
        id: "MSG-257",
        type: "MESSAGE"
      }
    },
    id: "SR-768633",
    content: {
      issueType: "",
      activity: "Custody",
      subActivity: "SUBACTIVITY_3",
      customer: {
        displayName: "ALLIANZ GLOBAL INVESTORS GMBH",
        bdrLevel: "EC",
        bdrId: "19460"
      }
    }
  },
  {
    metaData: {
      shortStatus: "Resolved",
      resolutionDateTime: "",
      caseLabel: "Opération importante à venir - Cash management FR",
      creationDateTime: resolvedDate.toISOString()
    },
    userMetaData: {
      alertCount: 0,
      lastSeenDateTime: resolvedDate.toISOString(),
      isCreator: true,
      isFavourite: false,
      lastAlertDateTime: resolvedDate.toISOString(),
      isContact: true,
      isShared: false,
      latestVisibleInteraction: {
        id: "MSG-259",
        type: "MESSAGE"
      }
    },
    id: "SR-768624",
    content: {
      issueType: "",
      activity: "Custody",
      subActivity: "SUBACTIVITY_4",
      customer: {
        displayName: "ALLIANZ GLOBAL INVESTORS GMBH",
        bdrLevel: "EC",
        bdrId: "19460"
      }
    }
  }
];

const getCasesMock = (): Case[] => mocks.map(m => ({ ...m }));
export default getCasesMock;
