import { FetchFn } from "@sg-widgets/react-core";

type ResponseStatus = 'notauthenticated' | 'notfound' | 'forbidden' | 'loaded' | 'failed';
export interface FetchResponse<T> {
    status: ResponseStatus;
    data: T | null
}

const doFetch = async <T>(fetch: FetchFn, input: RequestInfo, init?: RequestInit | undefined): Promise<FetchResponse<T>> => {
    const failResult: FetchResponse<T> = { status: 'failed', data: null };
    const mimeType = 'application/json';
    try {
        const response = await fetch(
            input, {
                ...(init || {}),
                headers: {
                    ...init?.headers || {},
                    Accept: mimeType,
                    'Content-Type': mimeType
                }
            }
        );

        switch (response.status) {
            case 401: 
                return {
                    status: 'notauthenticated',
                    data: null
                }
            case 204: 
            case 404: 
                return {
                    status: 'notfound',
                    data: null
                }
            case 403: 
                return {
                    status: 'forbidden',
                    data: null
                }
        }

        if (response.body) {
            const data = await response.json();
            return {
                status: "loaded",
                data
            };
        }

        return failResult;
    }
    catch {
        return failResult;
    }
  }

  export { doFetch }