import { ListItemContext, ListItemContextModel } from "@sgss-ttd-widgets/components";
import React, { Fragment, useContext } from "react";
import { CaseLight } from "../../models/my-cases/do-search-case.model";

const ItemTitleTemplate =  (): JSX.Element => {
    const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
    const item: CaseLight = itemContext?.item;
    return (
        <Fragment>
            <span>{ item.title }&nbsp;</span>
            <span className="text-secondary text-truncate">#{ item.caseReference }</span>
        </Fragment>
    );
}

  export default ItemTitleTemplate;