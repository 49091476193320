import React, { useContext } from "react";
import { ListItemContext, ListItemContextModel, popperStyleModifier } from "@sgss-ttd-widgets/components";
import { PopoverTrigger } from "@sgss-ttd-widgets/components";
import { withTranslation } from "react-i18next";
import { WithT } from "i18next";

import { CaseLight } from "../../models/my-cases/do-search-case.model";
import CaseDetail from "./case-detail";

const ItemInfoTemplate: React.FC<WithT<any>> = ({ t }) => {
    const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
    const item: CaseLight = itemContext?.item;
    const lastInteraction = item?.lastInteraction;
    let badge: JSX.Element;

    switch (item?.status) {
      case 'New':
        badge = <div className='badge badge-info badge-lg rounded-pill me-8px'>{t('ttdMyCases.statuses.new')}</div>;
        break;
      case 'In progress':
        badge = <div className='badge badge-success badge-lg rounded-pill me-8px'>{t('ttdMyCases.statuses.inProgress')}</div>;
        break;
      case 'Resolved':
        badge = <div className='badge badge-secondary badge-lg rounded-pill me-8px'>{t('ttdMyCases.statuses.resolved')}</div>;
        break
      default:
        badge = <></>;
        break;
    }

    return (
      <div className="d-flex align-items-center">
        { badge }
        { item.lastInteraction 
          ? (<PopoverTrigger
              placement="auto"
              content={
                <CaseDetail
                    item={ lastInteraction }
                    caseTitle={ item.title }
                    caseReference={ item.caseReference } />
              }
              popperConfig={{ 
                modifiers: [popperStyleModifier({
                  additionalStyles: { minWidth: '460px', width: '460px' }
                })]
              }}>
              <button className='btn btn-link btn-lg py-0 px-0'>
                <i className='icon'>info_outline</i>
              </button>
            </PopoverTrigger>)
           : <div style={{ height: '24px', width: '24px' }}></div>
        }
        </div>
      );
  };

  export default withTranslation()(ItemInfoTemplate);