import { WithT } from "i18next";
import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

type Props = {
  isInDemoMode?: boolean;
} & WithT<any>

const TitleTemplate = ({ 
  isInDemoMode,
  t
}: Props): JSX.Element => {
  return !isInDemoMode 
  ? <>
      <span>{t('ttdMyCases.title')}</span>
      <i className="icon icon-sm text-secondary ms-4px">launch</i>
    </> 
  : <>
    <span>{t('ttdMyCases.title')}</span>
    <i className="icon icon-sm text-secondary ms-4px">launch</i>
    <span className="badge badge-discreet-primary rounded-pill ms-4px">{t('ttdMyCases.demoBadge')}</span>
  </>
}

  export default withTranslation()(TitleTemplate);