import React, { Fragment } from "react";
import { format } from 'date-fns';
import { InteractionLight } from "../../models/my-cases/interaction.model";
import { WithT } from "i18next";
import { withTranslation } from "react-i18next";

type BodyProps = {
  item: InteractionLight | null | undefined;
} & WithT<any>;

const MessageBody: React.FC<BodyProps> = ({ 
  item,
  t
}): JSX.Element => (
  <Fragment>
     <div className="d-flex align-items-start mb-4">
        <div className="d-flex flex-column me-3">
          <div className="rounded-circle bg-lvl4 d-flex align-items-center justify-content-center text-secondary fw-semibold"
            style={{ minWidth: '36px', minHeight: '36px' }}>
            <div className="text-uppercase">
              { `${item?.senderFirstName.charAt(0)}${item?.senderLastName.charAt(0)}` }
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-truncate">
          <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex flex-column text-truncate">
              <div className="fw-semibold text-truncate mb-1">
                <span>{ item?.senderFirstName }</span>
                <span className="text-uppercase">&nbsp;{ item?.senderLastName }</span>
                <span className="text-secondary fw-normal">&nbsp;via {item?.channel || 'message'}</span>
              </div>
              <div className="text-truncate text-secondary">
                { item ? t('ttdMyCases.caseDetail.date', { date: item.date }) : '' } 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: item?.text ? item.text : '' }}>
      </div>  
  </Fragment>
);

const PhoneCallBody: React.FC<BodyProps> = ({ 
  item,
  t
}): JSX.Element => (
  <div className="d-flex align-items-start">
      <div className="d-flex flex-column me-3">
        <div
          className="rounded-circle bg-lvl4 d-flex align-items-center justify-content-center text-secondary fw-semibold"
          style={{ minWidth: '36px', minHeight: '36px' }}>
          <div className="text-uppercase">
            { `${item?.senderFirstName.charAt(0)}${item?.senderLastName.charAt(0)}` }
          </div>
        </div>
      </div>
      <div className="d-flex flex-column text-truncate">
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column text-truncate">
            <div className="fw-semibold text-truncate mb-1">
            <span>{ item?.senderFirstName }</span>
                <span className="text-uppercase">&nbsp;{ item?.senderLastName }</span>
                <span className="text-secondary fw-normal">&nbsp;via {item?.channel || 'message'}</span>
            </div>
            <div className="text-truncate text-secondary mb-3">
              { item ? t('ttdMyCases.caseDetail.date', { date: item.date }) : '' } 
            </div>
            <div
              className="d-flex flex-column bg-lvl1 border p-3"
              style={{ borderRadius: '16px'}} >
              <span className="fw-medium text-truncate mb-2">{t('ttdMyCases.caseDetail.callDetail.title')}</span>
              <div className="d-flex flex-column">
                <span className="text-secondary font-weight-medium text-truncate mb-1">
                  <span>{t('ttdMyCases.caseDetail.callDetail.type')}:</span>
                  <span className="text-primary fw-normal ms-2">
                    <span>{ item?.direction }</span>
                  </span>
                </span>
                <span className="text-secondary font-weight-medium text-truncate mb-1">
                  <span>{t('ttdMyCases.caseDetail.callDetail.from')}:</span>
                  <span className="text-primary fw-normal ms-2">
                    <span>{ item?.fromName }</span>
                  </span>
                </span>
                <span className="text-secondary font-weight-medium text-truncate mb-1">
                  <span>{t('ttdMyCases.caseDetail.callDetail.to')}:</span><span className="text-primary fw-normal ms-2">
                    <span>{ item?.toPhoneNumber }</span>
                  </span>
                </span>
                <span className="text-secondary font-weight-medium text-truncate mb-1">
                  <span>{t('ttdMyCases.caseDetail.callDetail.anwseredBy')}:</span>
                  <span className="text-primary fw-normal ms-2">
                    <span>{ item?.toName }</span>
                  </span>
                </span>
                <span className="text-secondary font-weight-medium text-truncate mb-1">
                  <span>{t('ttdMyCases.caseDetail.callDetail.time')}:</span>
                  <span className="text-primary fw-normal ms-2">
                    <span>{ item?.callDate ? t('ttdMyCases.caseDetail.callDetail.formattedTime', { date: item.callDate }) : '' } </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);

type Props = {
  item: InteractionLight | null | undefined;
  caseTitle: string;
  caseReference: string;
} & WithT<any>;

const CaseDetail: React.FC<Props> = ({ 
    item,
    caseTitle,
    caseReference,
    t
}: Props): JSX.Element => (
  <div>
    <div className="d-flex bg-lvl2 p-4">
      <div>
        { item?.isUnread ? (
          <span className="badge badge-info badge-md rounded-pill me-2">
          {t('ttdMyCases.caseDetail.titleBadge')}
        </span>
        ): <></> }
      </div>
      <span className="fs-16 fw-medium text-break">
        { caseTitle }
        <span className="text-secondary">&nbsp;#{ caseReference }</span>
      </span>
    </div>
    <div className="p-4">
      {/*<div className="fw-semibold text-secondary mb-4">
         {t('ttdMyCases.caseDetail.subtitle')}
      </div>*/}
      { item?.channel === "phone call"
        ? <PhoneCallBody item={item} t={t}></PhoneCallBody>
        : <MessageBody item={item} t={t}></MessageBody> }
    </div>
  </div>
);

export default  withTranslation()(CaseDetail);
