import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";

type Props = {
  helpClick?: () => void;
} & WithT<any>;

const ActionTemplate: React.FC<Props> = ({
  helpClick,
  t
}) => (
    <div className="d-flex">
      <button 
        className='btn btn-link btn-xl btn-icon p-0'
        onClick={() => helpClick && helpClick() }>
          <i className='icon material-symbols-outlined'>help_outline</i>
      </button>
    </div>
  );

  export default withTranslation()(ActionTemplate);