import { subDays } from "date-fns";
import { Interaction, InteractionChannel } from "../models/my-cases/interaction.model";

const newCaseDate = new Date();
const inProgessDate = subDays(new Date(), 1);
const resolvedDate  = subDays(new Date(), 2);

const mocks: { channel: InteractionChannel, interaction: Interaction }[] = [
  {
    channel: "message",
    interaction: {
        id: "MSG-254",
        metaData: {
          resourceType: "messages",
          isBodyLongerThanPreview: false,
          creationDateTime: "2023-05-15T13:15:06Z",
          createdBy: {
            operatorId: "",
            displayName: "John Smith",
            icId: "d8d45e22-7bd4-4433-a165-b4c2d392a119",
          },
          updateDateTime: newCaseDate.toISOString(),
          updatedBy: {
            operatorId: "",
            displayName: "John Smith",
            icId: "d8d45e22-7bd4-4433-a165-b4c2d392a119",
          },
          status: "SENT",
          canBeSeenByExternals: true,
        },
        content: {
          body: "Dear Partner, We’re looking to your operation. Be sure that we’ll revert to you as soon as possible. Thanks John",
          attachments: [],
        },
      }
  },
  {
    channel: "phone call",
    interaction: {
      id: "MSG-255",
      metaData: {
        initiationDateTime: newCaseDate.toISOString(),
        direction: "Incoming",
        isExternal: true,
        resourceType: "resourceType",
        creationDateTime: newCaseDate.toISOString(),
        createdBy: {
          operatorId: "operatorId",
          displayName: "John Smith",
          icId: "icId"
        },
        updateDateTime: newCaseDate.toISOString(),
        updatedBy: {
          operatorId: "operatorId",
          displayName: "John Smith",
          icId: "icId"
        },
        external: true
      },
      content: {
        from: {
          emailAddress: "fromEmailAddress",
          displayName: "Eric RABESANDRATANA",
          phoneNumber: "fromPhoneNumber"
        },
        to: {
          emailAddress: "toEmailAddress",
          displayName: "John Smith",
          phoneNumber: "+33769874563214"
        },
        note: {
          subject: "subject",
          body: "body"
        }
      }
    }
  },
  {
    channel: "phone call",
    interaction: {
        id: "MSG-256",
        metaData: {
          initiationDateTime: inProgessDate.toISOString(),
          direction: "Incoming",
          isExternal: true,
          resourceType: "resourceType",
          creationDateTime: inProgessDate.toISOString(),
          createdBy: {
            operatorId: "operatorId",
            displayName: "John Smith",
            icId: "icId"
          },
          updateDateTime: inProgessDate.toISOString(),
          updatedBy: {
            operatorId: "operatorId",
            displayName: "John Smith",
            icId: "icId"
          },
          external: true
        },
        content: {
          from: {
            emailAddress: "fromEmailAddress",
            displayName: "Eric RABESANDRATANA",
            phoneNumber: "fromPhoneNumber"
          },
          to: {
            emailAddress: "toEmailAddress",
            displayName: "John Smith",
            phoneNumber: "+33769874563214"
          },
          note: {
            subject: "subject",
            body: "body"
          }
        }
      }
  },
  {
    channel: "message",
    interaction: {
        id: "MSG-257",
        metaData: {
          resourceType: "messages",
          isBodyLongerThanPreview: false,
          creationDateTime: "2023-05-15T13:15:06Z",
          createdBy: {
            operatorId: "",
            displayName: "John Smith",
            icId: "d8d45e22-7bd4-4433-a165-b4c2d392a119",
          },
          updateDateTime: inProgessDate.toISOString(),
          updatedBy: {
            operatorId: "",
            displayName: "John Smith",
            icId: "d8d45e22-7bd4-4433-a165-b4c2d392a119",
          },
          status: "SENT",
          canBeSeenByExternals: true,
        },
        content: {
          body: "Dear Partner, We’re looking to your operation. Be sure that we’ll revert to you as soon as possible. Thanks John",
          attachments: [],
        },
      }
  },
  {
    channel: "mail",
    interaction: {
      id: "MSG-254",
      metaData: {
          resourceType: "emails",
          sentDateTime: newCaseDate.toISOString(),
          sentBy: {
              emailAddress: "salma.mahjoub@gmail.com",
              displayName: "salma mahjoub"
          },
          importance: "NORMAL",
          type: "INBOUND",
          linkType: "INITIAL_REQUEST",
          sensitivity: "UNDEFINED",
          canBeSeenByExternals: false
      },
      content: {
        subject: "Client Platform TEST",
        body: "<html><head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\"></head><body><div style=\"text-align: left;background: #c80000;color: white;\"><font size=\"2\">[EMETTEUR EXTERNE] / [EXTERNAL SENDER]<br>Soyez vigilant avant d'ouvrir les pièces jointes ou de cliquer sur les liens. En cas de doute, signalez le message via le bouton &quot;Message suspect&quot; ou consultez go/secu. <br>Be cautious before opening attachments or clicking on any links. If in doubt, use &quot;Suspicious email&quot; button or visit go/secu. </font></div><br>Client Platform TEST<div dir=\"auto\">- merci de laisser ce case ouvert 48h le temps des tests en Prod&nbsp;</div><div dir=\"auto\"><br></div><div dir=\"auto\">Merci</div><div dir=\"auto\">Salma MAHJOUB</div><div dir=\"auto\"><br></div></body></html>",
    }
    } as any
  },
  {
    channel: "message",
    interaction: {
        id: "MSG-259-DISABLED",
        metaData: {
          resourceType: "messages",
          isBodyLongerThanPreview: false,
          creationDateTime: "2023-05-15T13:15:06Z",
          createdBy: {
            operatorId: "",
            displayName: "John Smith",
            icId: "d8d45e22-7bd4-4433-a165-b4c2d392a119",
          },
          updateDateTime: resolvedDate.toISOString(),
          updatedBy: {
            operatorId: "",
            displayName: "John Smith",
            icId: "d8d45e22-7bd4-4433-a165-b4c2d392a119",
          },
          status: "SENT",
          canBeSeenByExternals: true,
        },
        content: {
          body: "Dear Partner, We’re looking to your operation. Be sure that we’ll revert to you as soon as possible. Thanks John",
          attachments: [],
        },
      }
  }
];

const getInteractionsMock = (): { channel: InteractionChannel, interaction: Interaction }[] => mocks.map(m => ({ ...m }));
export default getInteractionsMock;
