import { ListItemContext, ListItemContextModel } from "@sgss-ttd-widgets/components";
import React, { useContext } from "react";
import { CaseLight } from "../../models/my-cases/do-search-case.model";
import { withTranslation } from "react-i18next";
import { WithT } from "i18next";

const ItemTextTemplate: React.FC<WithT<any>> = ({ t }) => {
    const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
    const item: CaseLight = itemContext?.item;
    const { date } = item;
    const formatedDate =  t('common.itemDate', { date: date });

    return (
        <>{`${formatedDate} · ${item.subActivity}`}</>
    );
}

  export default withTranslation()(ItemTextTemplate);